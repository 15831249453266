<template>
  <div>
    <b-card-actions title="Buscar Prealertas" action-collapse>
      <b-card-text>
        <b-row>
          <b-col cols="12" class="mb-2">
            <b-alert variant="dark" show>
              <div class="alert-body">
                <span
                  >Selecciona los criterios de filtrado y haz click en
                  buscar.</span
                >
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-initialDate" label="Fecha inicial">
              <b-form-input
                id="i-initialDate"
                type="date"
                placeholder=""
                v-model="formSearchData.initialDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-finalDate" label="Fecha final">
              <b-form-input
                id="i-finalDate"
                type="date"
                placeholder=""
                v-model="formSearchData.finalDate"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Activo" label-for="i-status">
              <b-form-select
                :options="optionsStatus"
                v-model="formSearchData.status"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Retenido" label-for="i-retained">
              <b-form-select
                :options="optionsStatus"
                v-model="formSearchData.retained"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group label-for="i-initialDate" label="Buscar por">
              <b-form-input
                id="i-initialDate"
                type="text"
                placeholder=""
                v-model="formSearchData.strSearch"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2" class="offset-md-10" align="right">
            <b-button variant="primary" @click="searchAlerts">Buscar</b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card-actions>
    <b-card title="Listado de Pre-Alertas">
        <b-row>
          <b-col sm="12" md="8">
            <b-button variant="primary mr-1 mb-1" @click="openFormCreateAlerts">
              Crear nueva</b-button>
            <b-button variant="info mr-1 mb-1" @click="showModalEditAlert = true" >
              Editar</b-button>
            <b-button variant="danger mr-1 mb-1" @click="ConfirmDeleteAlert">
              Eliminar</b-button>
          </b-col>
          <b-col sm="12" md="2">
            <b-form-group
              label-for="i-perPage"
              label="Items por página"
              label-cols="7"
            >
              <b-form-input
                id="i-perPage"
                type="number"
                placeholder=""
                v-model="perPage"
                @change="searchAlerts"
                align="right"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="2">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="selectableTable"
              align="right"
              hide-goto-end-buttons
            ></b-pagination>
            <!-- <p class="mt-3">Página actual: {{ currentPage }}</p> -->
          </b-col>
        </b-row>
        <b-table
          striped
          hover
          responsive
          id="selectableTable"
          :items="items"
          :fields="fields"
          select-mode="single"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <!-- Example scoped slot for select state illustrative purposes -->
          <template #cell(seleccionado)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">
                <b-button
                  variant="success"
                  class="btn-icon rounded-circle"
                  size="sm"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </span>
              <!-- <span class="sr-only">Selected</span> -->
            </template>
            <template v-else>
              <b-button
                variant="outline-dark"
                class="btn-icon rounded-circle"
                size="sm"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <!-- <span class="sr-only">Not selected</span> -->
            </template>
          </template>

          <template #cell(Activo)="data">
            <b-badge variant="info">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(Tracking)="data">
            <b-badge variant="success">
              {{ data.value }}
            </b-badge>
          </template>

          <template #cell(Orden_de_compra)="data">
            <b-badge variant="warning">
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
    </b-card>
    <!-- Inicio Modal Editar -->
    <b-modal
      v-model="showModalEditAlert"
      cancel-title="Cancelar"
      ok-title="Guardar"
      cancel-variant="danger"
      id="modalEditAlert"
      title="Editar Alerta"
      size="lg"
      @ok="onFormEditButtonClick"
    >
      <validation-observer ref="editRules">
        <b-row>
          <b-col cols="12" class="mb-2">
            <b-alert variant="dark" show>
              <div class="alert-body">
                <span
                  >Diligencia los datos de la prealerta y haz click en
                  guardar.</span
                >
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-tracking" label="Tracking">
              <validation-provider
                #default="{ errors }"
                name="Tracking"
                rules="required"
              >
                <b-form-input
                  id="i-tracking"
                  type="text"
                  placeholder=""
                  v-model="formEditData.tracking"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-store" label="Tienda">
              <validation-provider
                #default="{ errors }"
                name="Tienda"
                rules="required"
              >
                <b-form-select
                  id="i-store"
                  placeholder=""
                  v-model="formEditData.storeId"
                  :options="dataStores"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-content" label="Contenido">
              <validation-provider
                #default="{ errors }"
                name="Contenido"
                rules="required"
              >
                <b-form-input
                  id="i-content"
                  type="text"
                  placeholder=""
                  v-model="formEditData.content"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-recipient" label="Destinatario">
              <validation-provider
                #default="{ errors }"
                name="Destinatario"
                rules="required"
              >
                <b-form-select
                  id="i-recipient"
                  v-model="formEditData.recipientId"
                  :options="dataRecipients"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="i-comValue" label="Valor comercial">
              <validation-provider
                #default="{ errors }"
                name="Valor_comercial"
                rules="required"
              >
                <b-form-input
                  id="i-comValue"
                  type="number"
                  placeholder=""
                  v-model="formEditData.commercialValue"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-safeValue" label="Valor asegurado">
              <validation-provider
                #default="{ errors }"
                name="Valor_asegurado"
                rules="required"
              >
                <b-form-input
                  id="i-safeValue"
                  type="number"
                  placeholder=""
                  v-model="formEditData.safeValue"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label-for="i-taskPosition"
              label="Posicion arancelaria"
            >
              <validation-provider
                #default="{ errors }"
                name="Posicion_arancelaria"
                rules="required"
              >
                <b-form-select
                  id="i-taskPosition"
                  type="text"
                  placeholder=""
                  v-model="formEditData.taxPositionId"
                  :options="dataTasksPositions"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="i-purchaseOrder" label="Orden de compra">
              <validation-provider
                #default="{ errors }"
                name="Orden_de_compra"
                rules="required"
              >
                <b-form-input
                  id="i-purchaseOrder"
                  type="text"
                  placeholder=""
                  v-model="formEditData.purchaseOrder"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group label-for="i-notes" label="Notas">
              <b-form-textarea
                id="i-notes"
                type="text"
                placeholder=""
                v-model="formEditData.notes"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-checkbox
              value="1"
              class="custom-control-primary"
              v-model="formEditData.status"
            >
              Activo
            </b-form-checkbox>
          </b-col>
          <b-col md="12">
            <b-form-checkbox
              value="1"
              class="custom-control-primary"
              v-model="formEditData.retained"
            >
              Retener
            </b-form-checkbox>
          </b-col>
        </b-row>
        <br />
      </validation-observer>
    </b-modal>
    <!-- Fin modal editar -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import store from "@/store";
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
  BButtonGroup,
  BButton,
  BTable,
  BPagination,
  BIcon,
  BModal,
} from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    BButton,
    BTable,
    BPagination,
    BIcon,
    BModal,
    BButtonGroup,
    vSelect,
  },
  data() {
    return {
      errors: [],
      perPage: 20,
      currentPage: 1,
      selectedRow: {},
      showModalEditAlert: false,
      dataTasksPositions: [],
      dataStores: [],
      dataRecipients: [],
      formSearchData: {
        initialDate: "",
        finalDate: "",
        status: "",
        strSearch: "",
      },
      formEditData: {
        tracking: "",
        storeId: "",
        content: "",
        recipientId: "",
        commercialValue: 0,
        safeValue: 0,
        taxPositionId: "",
        purchaseOrder: "",
        billCopy: [],
        status: 1,
        retained: 0,
        clientId: store.state.clients.infoUser.id,
      },
      optionsStatus: [
        { text: "Inactivas", value: "0" },
        { text: "Activas", value: "1" },
        { text: "Ambas", value: "" },
      ],
      optionsRetained: [
        { text: "Ambos", value: "" },
        { text: "Si", value: "1" },
        { text: "No", value: "0" },
      ],
      fields: [
        "seleccionado",
        "Activo",
        "ID",
        "Fecha",
        "Tracking",
        "Destinatario",
        "Tienda",
        "Contenido",
        "valor_asegurado",
        "valor_comercial",
        "orden_de_compra",
        "Notas",
      ],
      items: [],
    };
  },
  beforeMount() {
    this.searchAlerts();
  },
  created() {
    this.getTaxesPositions();
    this.getStoresFromService();
    this.getRecipientsFromService();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    onRowSelected(items) {
      this.selectedRow = items[0];
      // console.log(this.selectedRow);
      this.formEditData.tracking = this.selectedRow.Tracking;
      this.formEditData.purchaseOrder = this.selectedRow.orden_de_compra;
      this.formEditData.content = this.selectedRow.Contenido;
      this.formEditData.commercialValue = this.selectedRow.valor_comercial;
      this.formEditData.safeValue = this.selectedRow.valor_asegurado;
      this.formEditData.storeId = this.selectedRow.tiendaId;
      this.formEditData.recipientId = this.selectedRow.destinatarioId;
      this.formEditData.taxPositionId = this.selectedRow.posArancelId;
      this.formEditData.notes = this.selectedRow.Notas;
      this.formEditData.status = this.selectedRow.estadoCheck;
      this.formEditData.retained = this.selectedRow.retenerCheck;
    },
    searchAlerts() {
      let str = "Bearer " + this.$session.get("userData").access_token;
      this.$http
        .post(
          `api/casillero/clientes/prealertas/?page=${this.currentPage}`,
          {
            pageSize: this.perPage,
            estado: this.formSearchData.status,
            fechaIni: this.formSearchData.initialDate,
            fechafin: this.formSearchData.finalDate,
            str_busqueda: this.formSearchData.strSearch,
          },
          {
            headers: { Authorization: str },
          }
        )
        .then((response) => {
          // console.log(response.data.datos.data);
          this.formatDataforTable(response.data.datos.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataforTable(dataFromService) {
      this.items = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {};
        let destinatarioName = itemFromService.destinatario.nombre_completo;
        let destinatarioId = itemFromService.destinatario.id;
        let tiendaName = itemFromService.tienda.razon_social;
        let tiendaId = itemFromService.tienda.id;
        let posArancelId = itemFromService.posicion_arancelaria_id;
        itemFormated = {
          Activo: itemFromService.estado ? "Si" : "No",
          ID: itemFromService.id,
          Fecha: itemFromService.fecha,
          Tracking: itemFromService.tracking,
          Destinatario: destinatarioName,
          destinatarioId: destinatarioId,
          posAranacelId: posArancelId,
          estadoCheck: itemFromService.estado ? 1 : 0,
          retenerCheck: itemFromService.retener ? 1 : 0,
          Tienda: tiendaName,
          tiendaId: tiendaId,
          Contenido: itemFromService.contenido,
          valor_asegurado: itemFromService.valor_asegurado,
          valor_comercial: itemFromService.valor_comercial,
          orden_de_compra: itemFromService.orden_de_compra,
          Notas: itemFromService.notas,
        };
        formatedData.push(itemFormated);
        this.items = formatedData;
      });
    },
    openFormCreateAlerts() {
      this.$router.push({ name: "crear-pre-alertas" });
    },
    // Métodos para llenar campos de formulario de edición
    getTaxesPositions() {
      const userData = this.$session.get("userData");
      this.$http
        .get("api/casillero/posiciones_arancelarias", {
          headers: { Authorization: "Bearer " + userData.access_token },
        })
        .then((response) => {
          this.formatDataTaxesPositions(response.data.datos);
        });
    },
    formatDataTaxesPositions(dataFromService) {
      this.dataTasksPositions = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {
          value: itemFromService.id,
          text: itemFromService.descripcion,
        };
        formatedData.push(itemFormated);
        this.dataTasksPositions = formatedData;
      });
    },
    getStoresFromService() {
      const userData = this.$session.get("userData");
      this.$http
        .get("api/casillero/tiendas", {
          headers: { Authorization: "Bearer " + userData.access_token },
        })
        .then((response) => {
          this.formatDataStores(response.data.datos);
        });
    },
    formatDataStores(dataFromService) {
      this.dataStores = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {
          value: itemFromService.id,
          text: `${itemFromService.id} - ${itemFromService.razon_social}`,
        };
        formatedData.push(itemFormated);
        this.dataStores = formatedData;
      });
    },
    getRecipientsFromService() {
      this.$http
        .post(
          "api/casillero/clientes/destinatarios?page=1",
          {
            pageSize: 500,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.formatDataRecipients(response.data.datos.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    formatDataRecipients(dataFromService) {
      this.dataRecipients = [];
      let formatedData = [];
      dataFromService.forEach((itemFromService) => {
        let itemFormated = {
          value: itemFromService.id,
          text: `${itemFromService.primer_nombre} ${itemFromService.segundo_nombre} ${itemFromService.primer_apellido} ${itemFromService.segundo_apellido}`,
        };
        formatedData.push(itemFormated);
        this.dataRecipients = formatedData;
      });
    },
    EditAlert() {
      this.$http
        .post(
          `api/casillero/prealertas/${this.selectedRow.ID}`,
          {
            tracking: this.formEditData.tracking,
            tienda_id: this.formEditData.storeId,
            contenido: this.formEditData.content,
            destinatario_id: this.formEditData.recipientId,
            valor_comercial: this.formEditData.commercialValue,
            valor_asegurado: this.formEditData.safeValue,
            posicion_arancelaria_id: this.formEditData.taxPositionId,
            orden_de_compra: this.formEditData.ppurchaseOrder,
            notas: this.formEditData.notes,
            estado: this.formEditData.status,
            retener: this.formEditData.retained,
            cliente_id: this.formEditData.clientId,
          },
          {
            headers: {
              Authorization: `Bearer ${
                this.$session.get("userData").access_token
              }`,
            },
          }
        )
        .then((response) => {
          this.$swal({
            title: "Editar Prealerta",
            text: "Prelaera editada con éxito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            // console.log(response);
            this.showModalEditAlert = false;
            this.searchAlerts();
          });
        })
        .catch((errors) => {
          this.$swal({
            title: "Editar Prealerta",
            text: "Error al editar la prealerta",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            console.log(errors);
          });
        });
    },
    ConfirmDeleteAlert() {
      this.$swal({
        title: "¿Deseas eliminar la prealerta seleccionada?",
        text: "¡Recuerda que esta operación no es reversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-danger mr-2",
          cancelButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.DeleteAlert();
        }
      });
    },
    DeleteAlert() {
      this.$http
        .delete(`api/casillero/prealertas/${this.selectedRow.ID}`, {
          headers: {
            Authorization: `Bearer ${
              this.$session.get("userData").access_token
            }`,
          },
        })
        .then((response) => {
          this.$swal({
            title: "Editar Prealerta",
            text: "Prealerta eliminada con éxito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            console.log(response.data);
            this.searchAlerts();
          });
          s;
        })
        .catch((errors) => {
          this.$swal({
            title: "Editar Prealerta",
            text: "Error al eliminar prealerta",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            console.log(response.data);
            this.searchAlerts();
          });
        });
    },
    validationFormEdit() {
      return new Promise((resolve, reject) => {
        this.$refs.editRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onFormEditButtonClick(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationFormEdit().then((validForm) => {
        if (validForm) {
          this.EditAlert();
        }
      });
    },
  },
};
</script>

<style>
</style>
